import { Control, FormState } from 'react-hook-form'
import { View } from 'react-native'
import { useStyles } from 'react-native-unistyles'
import Species from '../../../enums/species'
import FosterService from '../../../services/fosterService'
import FormFieldDate from '../../form/FormFieldDate'
import FormFieldText from '../../form/FormFieldText'
import { FosterForm } from '../fosterFormSchema'
import FosterMedicalForm, {
  fosterMedicalFormStylesheet,
} from './FosterMedicalForm'

interface Props {
  control: Control<FosterForm, any>
  formState: FormState<FosterForm>
}

const FosterHealthCard = ({ control, formState }: Props) => {
  const { styles: fosterMedicalFormStyles } = useStyles(
    fosterMedicalFormStylesheet
  )

  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, isLoading } = FosterService.useFoster(fosterId)

  const { fosterList } = FosterService.useFosterList()
  const initialFosterData = fosterList.find((f) => f.id === fosterId)

  return (
    <View>
      <FosterMedicalForm
        control={control}
        formState={formState}
        isLoading={isLoading}
        species={initialFosterData?.species as Species}
      >
        <FormFieldText
          control={control}
          disabled={isLoading}
          errors={formState.errors}
          fieldName={'microchipId'}
          isLoading={isLoading}
          keyboardType="numeric"
          label="🏷️ Microchip ID"
          required={false}
          style={fosterMedicalFormStyles.medicalInfoInput}
        />
        {foster?.deceasedDate && (
          <FormFieldDate
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName={'deceasedDate'}
            isLoading={isLoading}
            label="🪦 Date of Death"
            required={false}
            style={fosterMedicalFormStyles.medicalInfoInput}
          />
        )}
      </FosterMedicalForm>
    </View>
  )
}

export default FosterHealthCard
