import React, { createContext, useContext } from 'react'
import { Control, FormState } from 'react-hook-form'
import { FosterForm } from './fosterFormSchema'
import useFosterForm from './useFosterForm'

interface FosterFormContextType {
  control: Control<FosterForm>
  formState: FormState<FosterForm>
}

const FosterFormContext = createContext<FosterFormContextType | undefined>(
  undefined
)

export const useFosterFormContext = () => {
  const context = useContext(FosterFormContext)
  if (!context) {
    throw new Error(
      'useFosterFormContext must be used within a FosterFormProvider'
    )
  }
  return context
}

export const FosterFormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { control, formState } = useFosterForm()

  return (
    <FosterFormContext.Provider value={{ control, formState }}>
      {children}
    </FosterFormContext.Provider>
  )
}
