import AsyncStorage from '@react-native-async-storage/async-storage'
import { create, StoreApi, UseBoundStore } from 'zustand'
import {
  devtools,
  persist,
  PersistStorage,
  StorageValue,
} from 'zustand/middleware'
import {
  AdvancedFilterSlice,
  createAdvancedFilterSlice,
} from './advancedFilterSlice'
import { AuthSlice, createAuthSlice } from './authStore'
import { BasicFiltersSlice, createBasicFilterSlice } from './basicFilterSlice'
import { BulkSelectSlice, createBulkSelectSlice } from './bulkSelectSlice'
import { createOrganizationSlice, OrganizationSlice } from './organizationStore'
import { createUsersSlice, UsersSlice } from './usersStore'

type AppStoreState = AdvancedFilterSlice &
  AuthSlice &
  BasicFiltersSlice &
  BulkSelectSlice &
  OrganizationSlice &
  UsersSlice

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never

const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S
) => {
  const store = _store as WithSelectors<typeof _store>
  store.use = {}
  for (const k of Object.keys(store.getState())) {
    ;(store.use as any)[k] = () => store((s) => s[k as keyof typeof s])
  }

  return store
}

const storage: PersistStorage<AppStoreState> = {
  getItem: async (name: string) => {
    // return null

    const item = await AsyncStorage.getItem(name)
    return item ? JSON.parse(item) : null
  },
  setItem: async (name: string, value: StorageValue<AppStoreState>) => {
    await AsyncStorage.setItem(name, JSON.stringify(value))
  },
  removeItem: async (name: string) => {
    await AsyncStorage.removeItem(name)
  },
}

const useAppStoreBase = create<AppStoreState>()(
  devtools(
    persist(
      (set, ...rest) => ({
        ...createAdvancedFilterSlice(set, ...rest),
        ...createAuthSlice(set, ...rest),
        ...createBasicFilterSlice(set, ...rest),
        ...createBulkSelectSlice(set, ...rest),
        ...createOrganizationSlice(set, ...rest),
        ...createUsersSlice(set, ...rest),
      }),
      {
        name: 'app-store',
        partialize: (state) => {
          // eslint-disable-next-line no-lone-blocks
          {
            // We strip the auth state out because we do _not_ want it persisted. we want to fetch the auth state on each app load

            const { auth, bulkSelect, ...rest } = state
            return rest
          }
        },
        // @ts-expect-error this is mad because of the partialize above, but we're following the docs and it works, so 🤷‍♂️
        storage: storage,
      }
    )
  )
)

// eslint-disable-next-line import/prefer-default-export
export const useAppStore = createSelectors(useAppStoreBase)
