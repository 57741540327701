import { useGateValue } from '@statsig/expo-bindings'
import { router } from 'expo-router'
import { View } from 'moti'
import { memo } from 'react'
import { Platform } from 'react-native'
import {
  Button,
  Divider,
  Icon,
  Modal,
  Portal,
  Text,
  TouchableRipple,
} from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { AppVersions, Feature } from '../../../constants'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import Logo from './Logo'
import OrganizationButton from './OrganizationButton'
import UserAvatar from './UserAvatar'

interface Props {
  visible: boolean
  hideModal: () => void
}

const SettingsModal = ({ visible, hideModal }: Props) => {
  const { styles, theme } = useStyles(stylesheet)
  const isEmployee = useGateValue(Feature.Employee)

  const currentOrganization = useAppStore.use.currentOrganization()
  const setCurrentOrganization = useAppStore.use.setCurrentOrganization()

  const currentUserQuery = trpc.user.current.useQuery({
    organizationId: currentOrganization.id,
  })
  const organizations = currentUserQuery.data?.organizations
    .map((org) => org.organization)
    .filter((org) => org.id !== currentOrganization.id)

  const userFullName = `${currentUserQuery.data?.firstName} ${currentUserQuery?.data?.lastName}`

  const updateCurrentOrganization = (
    id: string,
    logo: string,
    name: string
  ) => {
    if (currentOrganization.id !== id) {
      setCurrentOrganization({
        id: id,
        logo: logo || '',
        name,
      })
    }
  }

  return (
    <Portal>
      <Modal
        contentContainerStyle={styles.root}
        onDismiss={hideModal}
        visible={visible}
      >
        <View style={styles.logoContainer}>
          <Logo style={styles.logo} />
        </View>
        <View style={styles.innerContainer}>
          <View style={styles.rowContainer}>
            <UserAvatar size={40} style={styles.avatar} />
            <Text style={styles.title}>{userFullName}</Text>
          </View>
          <Button icon="account-edit" mode="outlined" onPress={() => {}}>
            Manage Account
          </Button>
          <OrganizationButton
            id={currentOrganization.id || ''}
            logo={currentOrganization.logo}
            name={currentOrganization.name}
          />
          <View
            style={{
              flexDirection: 'row',
              gap: theme.tokens.spacing[1],
              justifyContent: 'space-evenly',
            }}
          >
            <Button
              icon="account-multiple"
              mode="outlined"
              onPress={() => {
                router.push('/organization/settings')
                hideModal()
              }}
            >
              Manage Org
            </Button>
            <Button
              icon="account-multiple"
              mode="outlined"
              onPress={() => {
                router.push('/users')
                hideModal()
              }}
            >
              Manage Users
            </Button>
          </View>
          <Divider
            style={{
              marginTop: theme.tokens.spacing[4],
            }}
          />
          {organizations?.map((org) => (
            <OrganizationButton
              id={org.id}
              key={org.id}
              logo={org.logo}
              name={org.name}
              onPress={() => {
                updateCurrentOrganization(org.id, org.logo || '', org.name)
                hideModal()
              }}
            />
          ))}
          <TouchableRipple
            accessibilityRole="button"
            borderless={true}
            onPress={() => {
              console.log('Not Implemented')
            }}
            style={[styles.rowContainer, { borderRadius: 16 }]}
          >
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Icon size={40} source="account-multiple-plus-outline" />
              <Text style={{ marginLeft: 10 }}>Create Organization</Text>
            </View>
          </TouchableRipple>
        </View>
        <View
          style={{
            marginTop: theme.tokens.spacing[4],
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={styles.versionText}>
            {Platform.OS === 'web'
              ? AppVersions.expoVersion
              : AppVersions.nativeVersion}
            -{AppVersions.gitSha}
          </Text>
          {isEmployee && (
            <>
              <Text style={styles.versionText}>
                Git Sha: {AppVersions.gitSha}
              </Text>
              <Text style={styles.versionText}>
                Native Version: {AppVersions.nativeVersion}
              </Text>
              <Text style={styles.versionText}>
                JS Bundle Version: {AppVersions.expoVersion}
              </Text>
              {Platform.OS !== 'web' && (
                <Text style={styles.versionText}>
                  Runtime Version: {AppVersions.runtimeVersion?.slice(0, 9)}
                </Text>
              )}
            </>
          )}
        </View>
      </Modal>
    </Portal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    avatar: {
      marginRight: theme.tokens.spacing[3],
    },
    innerContainer: {
      backgroundColor: theme.colors.surface,
      borderRadius: theme.tokens.containerBorderRadius * 2,
      gap: theme.tokens.spacing[2],
      padding: theme.tokens.spacing[3],
    },
    logo: {
      marginBottom: theme.tokens.spacing[4],
    },
    logoContainer: {
      alignItems: 'center',
    },
    root: {
      alignSelf: 'center',
      backgroundColor: theme.colors.surfaceVariant,
      borderRadius: theme.tokens.containerBorderRadius * 2,
      maxWidth: '90%',
      paddingHorizontal: theme.tokens.spacing[3],
      paddingVertical: theme.tokens.spacing[4],
    },
    rowContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.tokens.spacing[4],
    },
    title: {
      fontWeight: 'bold',
    },

    versionText: {
      color: theme.colors.onSurfaceDisabled,
    },
  }
})

export default memo(SettingsModal)
