import { View } from 'react-native'
import { Text } from 'react-native-paper'
import {
  createStyleSheet,
  UnistylesRuntime,
  useStyles,
} from 'react-native-unistyles'
import FosterService from '../../../services/fosterService'
import trpc from '../../../utils/trpc'
import Card from '../../shared/Card'
import DocumentsListItem from '../documents/DocumentListItem'

const FosterDocumentsCard = () => {
  const { styles } = useStyles(stylesheet)

  const { fosterId } = FosterService.useCurrentFoster()

  const { foster, refreshFoster } = FosterService.useFoster(fosterId)

  const documentDeleteMutation = trpc.foster.documents.delete.useMutation()

  const hasDocuments = !!foster?.documents?.length

  return (
    <View>
      <Card title="Documents">
        {!hasDocuments && <Text>No documents uploaded.</Text>}
        {hasDocuments && UnistylesRuntime.breakpoint !== 'xs' && (
          <View style={styles.headerContainer}>
            <Text style={[styles.nameColumnFlex, styles.headerColumn]}>
              Name
            </Text>
            <Text style={[styles.columnFlex, styles.headerColumn]}>Type</Text>
            <Text style={[styles.columnFlex, styles.headerColumn]}>
              Uploaded On
            </Text>
          </View>
        )}
        {hasDocuments &&
          foster?.documents.map((document) => {
            return (
              <>
                {
                  <DocumentsListItem
                    documentId={document.id}
                    key={document.id}
                    onDelete={async () => {
                      await documentDeleteMutation.mutateAsync({
                        id: document.id,
                      })

                      await refreshFoster()
                    }}
                  />
                }
              </>
            )
          })}
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    columnFlex: {
      flex: {
        xs: 1,
        sm: 0.3,
      },
    },
    headerColumn: {
      color: theme.colors.primary,
      fontWeight: 'bold',
    },
    headerContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 8,
    },
    nameColumnFlex: {
      flex: {
        xs: 1,
        sm: 0.5,
      },
    },
  }
})

export default FosterDocumentsCard
