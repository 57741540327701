import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { z } from 'zod'
import Role from '../../../enums/role'
import useAutosave from '../../../hooks/useAutoSave'
import UserService from '../../../services/userService'
import FormFieldText from '../../form/FormFieldText'
import Card from '../../shared/Card'
import NotFound from '../../shared/NotFound'
import Skeleton from '../../shared/Skeleton'
import UserRoles from './UserRoles'

const UserDetailForm = () => {
  const { styles } = useStyles(stylesheet)

  const { user, isLoading, currentOrganization } = UserService.useUser()

  const defaultValues = { ...user }
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })
  const {
    control,
    formState: { errors },
    reset,
    setValue,
  } = form

  useEffect(() => {
    reset(user)
  }, [reset, user])

  const onSubmit = async (data: FormData) => {
    if (!currentOrganization.id) {
      return
    }

    updateMutation.mutate(
      { ...data, organizationId: currentOrganization.id },
      {
        onSuccess: (updatedData) => {
          reset({ ...updatedData }, { keepDirtyValues: true })
        },
      }
    )
  }

  useAutosave({
    form,
    defaultValues,
    onSave: onSubmit,
  })

  const updateMutation = UserService.useUpdateMutation()
  const disabled = !!user?.deactivatedAt || isLoading

  if (!isLoading && !user) return <NotFound />

  const setUserRoles = (roles: Role[]) => {
    setValue('roles', roles, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
  }

  return (
    <Skeleton.Group show={isLoading}>
      <View style={styles.cardContainer}>
        <Card style={styles.infoCard} title="User Info">
          <View style={styles.formContainer}>
            <FormFieldText
              control={control}
              disabled={disabled}
              errors={errors}
              fieldName="firstName"
              isLoading={isLoading}
              label="First Name"
              required={true}
              style={{ flex: 2 }}
            />
            <FormFieldText
              control={control}
              disabled={disabled}
              errors={errors}
              fieldName="lastName"
              isLoading={isLoading}
              label="Last Name"
              required={true}
              style={{ flex: 2 }}
            />
            <FormFieldText
              control={control}
              disabled={disabled}
              errors={errors}
              fieldName="email"
              isLoading={isLoading}
              keyboardType="email-address"
              label="Email"
              required={false}
              style={{ flex: 3 }}
            />
          </View>
        </Card>
        <Card style={styles.rolesCard} title="Roles">
          <UserRoles
            disabled={disabled}
            isLoading={isLoading}
            setUserRoles={setUserRoles}
            userRoles={form.getValues().roles as Role[]}
          />
        </Card>
      </View>
    </Skeleton.Group>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  cardContainer: {
    flexDirection: {
      lg: 'row',
    },
    gap: theme.tokens.spacing[4],
  },
  formContainer: {
    flexDirection: {
      md: 'row',
    },
    gap: theme.tokens.spacing[4],
  },
  infoCard: {
    flex: {
      lg: 3,
    },
  },
  rolesCard: {
    flex: {
      lg: 1,
    },
  },
}))

const formSchema = z.object({
  id: z.string().uuid(),
  firstName: z.string().min(1, { message: 'Required' }),
  lastName: z.string().min(1, { message: 'Required' }),
  email: z.string().email(),
  roles: z.array(z.enum(Object.values(Role) as [`${Role}`])),
})

type FormData = z.infer<typeof formSchema>

export default UserDetailForm
