import { useState } from 'react'
import DocumentScanner, {
  ResponseType,
  ScanDocumentResponseStatus,
} from 'react-native-document-scanner-plugin'
import { FAB } from 'react-native-paper'
import { useStyles } from 'react-native-unistyles'
import useFosterOwner from '../../hooks/useFosterOwner'
import FosterService from '../../services/fosterService'
import Snackbar from '../snackbar/Snackbar'
import NewAdoptionAction from './actions/NewAdoptionAction'
import ReturnAdoptionAction from './actions/ReturnAdoptionAction'
import ScanDocumentAction from './actions/ScanDocumentAction'
import useDeleteAction from './actions/useDeleteAction'
import useMarkDeceased from './actions/useMarkDeceased'
import usePrintPdfAction from './actions/usePrintPdfAction'

type FosterActionButtonProps = {
  currentOrganizationId: string
  fosterId: string
}

const FosterActionButton = ({
  currentOrganizationId,
  fosterId,
}: FosterActionButtonProps) => {
  const { theme } = useStyles()

  const { foster } = FosterService.useFoster(fosterId)

  const [state, setState] = useState({ open: false })
  const [newAdoptionRecordOpen, setNewAdoptionRecordOpen] = useState(false)
  const [returnAdoptionOpen, setReturnAdoptionOpen] = useState(false)
  const [scanResults, setScanResults] = useState<string[] | null>(null)
  const onStateChange = ({ open }: { open: boolean }) => setState({ open })

  const fosterOwner = useFosterOwner(fosterId)

  const pdfAction = usePrintPdfAction(fosterId, currentOrganizationId)
  const { deleteFoster, isDeleting } = useDeleteAction()
  const { markDeceased } = useMarkDeceased()

  const { open } = state

  // We prefetch some things when the FAB is opened to reduce the perceived latency for the end-user
  if (open && !isDeleting) {
    pdfAction.prefetch()
  }

  const scanDocument = async () => {
    const result = await DocumentScanner.scanDocument({
      croppedImageQuality: 100,
      responseType: ResponseType.ImageFilePath,
    })

    if (result.status === ScanDocumentResponseStatus.Success) {
      if (!result.scannedImages) {
        Snackbar.error(
          'There was an error scanning the documents. Please try again.'
        )
        return
      }

      setScanResults(result.scannedImages)
    }
  }

  return (
    <>
      <FAB.Group
        actions={[
          ...(fosterOwner
            ? [
                {
                  icon: 'delete',
                  label: 'Delete',
                  color: theme.colors.error,
                  onPress: () => {
                    deleteFoster()
                  },
                  testID: 'delete-action',
                },
                ...(!foster?.deceasedDate
                  ? [
                      {
                        icon: 'grave-stone',
                        label: 'Mark Deceased',
                        onPress: () => {
                          markDeceased()
                        },
                        testID: 'mark-deceased-action',
                      },
                    ]
                  : []),
              ]
            : []),
          {
            icon: 'newspaper-variant',
            label: 'Print Cover Sheet',
            onPress: async () => {
              await pdfAction.openPDF()
            },
            testID: 'print-cover-sheet-action',
          },
          {
            ...(foster?.isAdopted
              ? {
                  icon: 'clipboard-minus',
                  label: 'Mark Returned',
                  onPress: () => {
                    setReturnAdoptionOpen(true)
                  },
                  testID: 'adoption-return-action',
                }
              : {
                  icon: 'clipboard-plus',
                  label: 'New Adoption Record',
                  onPress: () => {
                    setNewAdoptionRecordOpen(true)
                  },
                  testID: 'new-adoption-record-action',
                }),
          },
          {
            icon: 'scanner',
            label: 'Scan Documents',
            onPress: () => scanDocument(),
            testID: 'scan-documents-action',
          },
        ]}
        icon={open ? 'close' : 'menu'}
        onStateChange={onStateChange}
        open={open}
        style={{
          position: 'absolute',
        }}
        testID="foster-action-button"
        visible
      />
      {scanResults?.length && (
        <ScanDocumentAction
          currentOrganizationId={currentOrganizationId}
          fosterId={fosterId}
          onDismiss={() => setScanResults(null)}
          scanUris={scanResults}
        />
      )}
      <NewAdoptionAction
        onClose={() => setNewAdoptionRecordOpen(false)}
        open={newAdoptionRecordOpen}
      />

      <ReturnAdoptionAction
        onClose={() => setReturnAdoptionOpen(false)}
        open={returnAdoptionOpen}
      />
    </>
  )
}

export default FosterActionButton
