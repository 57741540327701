import { Linking, Platform, View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import Snackbar from '../../../components/snackbar/Snackbar'
import { useAppStore } from '../../../store/useAppStore'
import trpc from '../../../utils/trpc'

const OrganizationSettings = () => {
  const currentOrganization = useAppStore.use.currentOrganization()

  const checkoutQuery = trpc.organization.checkout.useQuery(
    {
      organizationId: currentOrganization.id || '',
      // TODO build a proper UI for selecting a plan
      priceId: 'price_1PIXinARQyFfBoHPhl0QpupS',
    },
    {
      enabled: !!currentOrganization.id,
    }
  )

  async function fetchCheckoutSession() {
    const url = checkoutQuery.data?.url

    if (!url) {
      // TODO handle this better
      Snackbar.error('There was a problem doing this')
      return
    }

    Linking.openURL(url)
  }

  return (
    <View>
      {
        //  We're not interested in paying the Apple or Google tax,
        // so we'll only provide the subscription management features on the web
        Platform.OS === 'web' ? (
          <Button
            icon="hand-coin"
            mode="contained"
            onPress={fetchCheckoutSession}
            style={{ width: 200 }}
          >
            Subscribe
          </Button>
        ) : (
          <Text>Subscription management is only available on the web</Text>
        )
      }
    </View>
  )
}

export default OrganizationSettings
