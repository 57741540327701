import { zodResolver } from '@hookform/resolvers/zod'
import { Image } from 'expo-image'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Button } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import { DocumentSelectorTypes as DocumentTypeSelectors } from '../../../../constants'
import DocumentType from '../../../enums/documentType'
import trpc from '../../../utils/trpc'
import FormFieldDate from '../../form/FormFieldDate'
import FormFieldSelect from '../../form/FormFieldSelect'
import FormFieldText from '../../form/FormFieldText'

const formSchema = z.object({
  createdAt: z.date().nullable(),
  type: z.nativeEnum(DocumentType),
  name: z.string().min(8),
})

type FormData = z.infer<typeof formSchema>

interface Props {
  documentId: string
  onSubmit: () => void
}

const DocumentEdit = ({ documentId, onSubmit }: Props) => {
  const { styles } = useStyles(stylesheet)

  const documentQuery = trpc.foster.documents.byId.useQuery(
    {
      id: documentId,
    },
    {
      enabled: true,
    }
  )

  const documentMutationQuery = trpc.foster.documents.update.useMutation()

  const document = documentQuery.data

  const documentForm = useForm<FormData>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...document,
      type: document?.type as DocumentType,
    },
  })

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = documentForm

  const onInnerSubmit = handleSubmit(async (data) => {
    await documentMutationQuery.mutateAsync(
      {
        ...data,
        id: documentId,
      },
      {
        onSuccess: (updatedData) => {
          reset({
            ...updatedData,
            type: updatedData.type as DocumentType,
          })
          documentQuery.refetch()
        },
      }
    )

    documentQuery.refetch()

    onSubmit()
  })

  return (
    <View style={styles.root}>
      <Image
        accessibilityIgnoresInvertColors={true}
        cachePolicy="memory"
        contentFit="contain"
        key={`${document?.id}-preview`}
        source={{ uri: document?.url }}
        style={styles.imagePreview}
        transition={400}
      />
      <View style={styles.formContainer}>
        <FormFieldSelect
          control={control}
          data={DocumentTypeSelectors}
          errors={errors}
          fieldName="type"
          label="Select Type"
          required={false}
        />
        <FormFieldText
          control={control}
          errors={errors}
          fieldName="name"
          label="Name"
          required={true}
        />
        <FormFieldDate
          control={control}
          errors={errors}
          fieldName="createdAt"
          label="Created At"
          required={false}
        />
        <Button
          disabled={!isDirty || documentMutationQuery.isLoading}
          loading={documentMutationQuery.isLoading}
          mode="contained"
          onPress={onInnerSubmit}
          style={{ marginTop: 5 }}
        >
          Update
        </Button>
      </View>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    formContainer: {
      alignSelf: {
        md: undefined,
        lg: 'center',
      },
      flex: {
        md: undefined,
        lg: 0.55,
      },
      flexDirection: 'column',
      gap: theme.tokens.spacing[2],
    },
    imagePreview: {
      flex: {
        xs: 0.95,
        md: 0.95,
        lg: 0.5,
      },
    },
    root: {
      flex: 1,
      flexDirection: {
        md: 'column',
        lg: 'row',
      },
      gap: theme.tokens.spacing[4],
    },
  }
})

export default DocumentEdit
