import { zodResolver } from '@hookform/resolvers/zod'
import { AppRouter } from '@rescuebase/api/src/router/app-router'
import type { inferRouterOutputs } from '@trpc/server'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import useAutoSave from '../../hooks/useAutoSave'
import FosterService from '../../services/fosterService'
import trpc from '../../utils/trpc'
import { prepareFormForSubmission } from '../form/formUtils'
import Snackbar from '../snackbar/Snackbar'
import fosterFormSchema, { FosterForm } from './fosterFormSchema'

type RouterOutput = inferRouterOutputs<AppRouter>

// We only include the explicit fields in our form, because the foster response is
// much more complicated and will trigger infinite re-renders in our autosave hook otherwise.
const fosterDefaults = (
  foster:
    | RouterOutput['foster']['byId']
    | RouterOutput['foster']['update']
    | undefined
) => {
  if (!foster) {
    return {}
  }

  return {
    birthDate: foster?.birthDate,
    breed: foster?.breed,
    deceasedDate: foster?.deceasedDate,
    clearFecalTestDate: foster?.clearFecalTestDate,
    comboTestingCompletedDate: foster?.comboTestingCompletedDate,
    currentFood: foster?.currentFood,
    fecalFollowUpDate: foster?.fecalFollowUpDate,
    felvPositive: foster?.felvPositive,
    fivPositive: foster?.fivPositive,
    fixedBeforeIntake: foster?.fixedBeforeIntake,
    fixedOnDate: foster?.fixedOnDate,
    gender: foster?.gender,
    heartwormPositive: foster?.heartwormPositive,
    heartwormTestingCompletedDate: foster?.heartwormTestingCompletedDate,
    history: foster?.history,
    intakeDate: foster?.intakeDate,
    isAdopted: foster?.isAdopted,
    isHospice: foster?.isHospice,
    microchipId: foster?.microchipId,
    name: foster?.name,
    nextComboMedDue: foster?.nextComboMedDue,
    nextFleaTickMedDue: foster?.nextFleaTickMedDue,
    nextHeartwormPreventionDue: foster?.nextHeartwormPreventionDue,
    notes: foster?.notes,
    positiveFecalTestDate: foster?.positiveFecalTestDate,
    privateNotes: foster?.privateNotes,
    readyForAdoption: foster?.readyForAdoption,
    species: foster?.species,
    training: foster?.training,
    userId: foster?.user?.id as string,
    weight: foster?.weight?.toString(),
  }
}

const useFosterForm = () => {
  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, refreshFoster } = FosterService.useFoster(fosterId)
  const { refreshFosterList } = FosterService.useFosterList()

  const fosterUpdateMutation = trpc.foster.update.useMutation()

  const defaultValues = useMemo(() => fosterDefaults(foster), [foster])

  const form = useForm<FosterForm>({
    mode: 'onBlur',
    resolver: zodResolver(fosterFormSchema),
    defaultValues: defaultValues,
  })

  const { control, formState, handleSubmit, reset } = form

  useEffect(() => {
    reset(defaultValues, { keepDirtyValues: true })
  }, [defaultValues, reset])

  const onSubmit = () => {
    handleSubmit((data) => {
      fosterUpdateMutation.mutate(
        {
          ...prepareFormForSubmission(data),
          id: fosterId,
          vaccinations: [],
          weight: data.weight ? parseFloat(data.weight) : null,
        },
        {
          onError: () => {
            Snackbar.error('There was a problem saving this foster')
          },
          onSuccess: async (updatedData) => {
            reset(fosterDefaults(updatedData), {
              keepDirtyValues: true,
            })
            await refreshFoster()
            await refreshFosterList()
          },
        }
      )
    })()
  }

  useAutoSave({
    form,
    defaultValues,
    onSave: onSubmit,
  })

  return {
    control,
    formState,
  }
}

export default useFosterForm
