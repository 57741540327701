import { useActionSheet } from '@expo/react-native-action-sheet'
import { createRef, useState } from 'react'
import { Alert, findNodeHandle, Platform, View } from 'react-native'
import ReactNativeBlobUtil from 'react-native-blob-util'
import { Text, TouchableRipple } from 'react-native-paper'
import {
  createStyleSheet,
  UnistylesRuntime,
  useStyles,
} from 'react-native-unistyles'
import log from '../../../utils/datadog/log/log'
import trpc from '../../../utils/trpc'
import Snackbar from '../../snackbar/Snackbar'
import DocumentEditModal from './DocumentEditModal'

interface Props {
  documentId: string
  onDelete: () => void
}

const DocumentsListItem = ({ documentId, onDelete }: Props) => {
  const { styles } = useStyles(stylesheet)
  const { showActionSheetWithOptions } = useActionSheet()
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false)

  const _anchorRef = createRef<any>()

  const documentQuery = trpc.foster.documents.byId.useQuery(
    {
      id: documentId,
    },
    {
      enabled: true,
    }
  )

  const document = documentQuery.data

  if (!document) {
    return null
  }

  if (documentQuery.isInitialLoading) {
    return null
  }

  const onShare = async () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Share = require('react-native-share').default

    try {
      if (!documentQuery.data?.url) {
        await documentQuery.refetch()
        if (!documentQuery.data?.url) {
          throw new Error('Document URL not found')
        }
      }

      Snackbar.info('Preparing to share document')

      const mimeType = document.name.includes('.pdf')
        ? 'application/pdf'
        : 'image/*'

      const response = await ReactNativeBlobUtil.fetch(
        'GET',
        documentQuery.data.url
      )

      await Share.open({
        url: `data:image/png;base64,${response.data}`,
        type: mimeType,
        failOnCancel: false,
        filename: document.name,
      })
    } catch (error: any) {
      Snackbar.error('There was a problem sharing the document')

      log.error('Error sharing document', {
        documentId: document.id,
        error,
      })
    }
  }

  const onPress = () => {
    const shareOrOpen = Platform.OS === 'web' ? 'Open' : 'Share'
    const options = [shareOrOpen, 'Edit', 'Delete', 'Cancel']
    const shareOpenButtonIndex = 0
    const editButton = 1
    const destructiveButtonIndex = 2
    const cancelButtonIndex = 3

    showActionSheetWithOptions(
      {
        anchor: findNodeHandle(_anchorRef.current) || undefined,
        useModal: true,
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (selectedIndex: number | undefined) => {
        switch (selectedIndex) {
          case shareOpenButtonIndex:
            if (Platform.OS === 'web') {
              window.open(documentQuery.data?.url)
            } else {
              onShare()
            }
            break

          case editButton:
            setIsEditingModalOpen(true)
            break
          case destructiveButtonIndex:
            Alert.alert(
              'Delete',
              'Are you sure you want to delete this document?',
              [
                {
                  text: 'Cancel',
                  onPress: () => console.log('Cancel Pressed'),
                  style: 'cancel',
                },
                {
                  isPreferred: false,
                  text: 'Delete',
                  onPress: () => onDelete(),
                  style: 'destructive',
                },
              ]
            )
            break

          case cancelButtonIndex:
            break
        }
      }
    )
  }

  return (
    <>
      <DocumentEditModal
        documentId={documentId}
        onClose={() => setIsEditingModalOpen(false)}
        open={isEditingModalOpen}
      />
      <TouchableRipple
        accessibilityRole="button"
        onPress={onPress}
        ref={_anchorRef}
        style={{
          flex: 1,
          width: '100%',
          borderRadius: 4,
        }}
      >
        <View style={styles.documentContainer}>
          <Text
            ellipsizeMode="tail"
            numberOfLines={1}
            style={[styles.nameColumnFlex]}
          >
            {document.name}
          </Text>
          {UnistylesRuntime.breakpoint !== 'xs' ? (
            <>
              <Text style={[styles.columnFlex, styles.columnContent]}>
                {document.type}
              </Text>
              <Text style={[styles.columnFlex, styles.columnContent]}>
                {document.createdAt.toDateString()}
              </Text>
            </>
          ) : (
            <Text style={[styles.columnFlex, styles.columnContent]}>
              Justin Vanderhooft • {document.createdAt.toDateString()}
            </Text>
          )}
        </View>
      </TouchableRipple>
    </>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    columnContent: {
      color: {
        xs: theme.colors.subtitle,
        sm: theme.colors.onBackground,
      },
      fontSize: {
        xs: 12,
        sm: 14,
      },
    },
    columnFlex: {
      flex: {
        xs: 1,
        sm: 0.3,
      },
    },
    documentContainer: {
      alignItems: {
        xs: 'flex-start',
        sm: 'center',
      },
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      height: {
        xs: 60,
        sm: 40,
      },
      padding: 8,
    },
    nameColumnFlex: {
      flex: {
        xs: 1,
        sm: 0.5,
      },
    },
  }
})

export default DocumentsListItem
